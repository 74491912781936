
import {defineComponent, ref, watch} from "vue";

export default defineComponent({
  name: "InfoBox",
  props: {
    type: {default: 'info'},
    show: {default: true},
    card: {default: false} //wrapped in the card
  },
  setup(props) {
    const clazz = ref('bg-light-infobox border-primary')
    const build = (type) => {
      switch (type) {
        case 'warn':
          clazz.value = 'bg-light-warning border-warning';
          break
        case 'alert':
          clazz.value = ' bg-light-danger  border-danger';
          break
        default:
          clazz.value = 'bg-light-infobox border-primary';
      }
    }
    watch(() => props.type, cb => {
      build(cb);
    })
    build(props.type);
    return {
      clazz,
    }
  }
})
